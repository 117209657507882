<template>
    <div class="rq-container">
        <div class="step-title">Step {{stepNumber}}</div>
        <rq-page-section
            title="Title Production and Policy Data for Order Assigned Language"
            header-size="lg"
            borderless>
            <div class="row">
                <div class="col" style="max-width: 480px">
                    <div class="dx-grid-container">
                        <span>Pending Assigned Language</span>
                        <dx-tree-list
                            id="pendingTreeList"
                            ref="pendingTreeList"
                            key-expr="id"
                            height="400px"
                            parent-id-expr="parentId"
                            :data-source="pendingLanguageDataSource"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :allow-column-resizing="true"
                            v-model:expanded-row-keys="expandedPendingRowKeys"
                            v-model:selected-row-keys="selectedPendingRowKeys"
                            @selection-changed="onSelectionChanged">
                            <dx-selection mode="multiple" />
                            <dx-editing
                                :allow-adding="false"
                                :allow-updating="false"
                                :allow-deleting="false"
                                mode="row"
                            />
                            <dx-column
                                data-field="descriptionDisplay"
                                data-type="string"
                                caption="Description"
                                :width="230"
                                >
                            </dx-column>
                            <dx-column
                                data-field="code"
                                data-type="string"
                                caption="Code"
                                :width="120"
                                >
                            </dx-column>
                            <dx-column
                                data-field="appliesToDisplay"
                                data-type="string"
                                caption="Applies To"
                                :width="90"
                                >
                            </dx-column>
                        </dx-tree-list>
                    </div>
                </div>
                <div class="col col-1" style="max-width: 25px">
                    <div class="row" style="padding-top: 180px; padding-bottom: 30px">
                        <span v-rq-tooltip.top.hover.html :title="`${moveRightDisabled() ? 'Select a Pending item and a target section to move into.' : ''}`">
                            <b-btn
                                variant="theme"
                                class="btn-action"
                                :disabled="moveRightDisabled()"
                                @click="moveRight">
                                <FontAwesomeIcon icon="fas fa-angle-right" />
                            </b-btn>
                        </span>
                    </div>
                    <div class="row">
                        <span v-rq-tooltip.top.hover.html :title="`${moveLeftDisabled() ? 'Select an item previously moved from Pending.' : ''}`">
                            <b-btn
                                variant="theme"
                                class="btn-action"
                                :disabled="moveLeftDisabled()"
                                @click="moveLeft">
                                <FontAwesomeIcon icon="fas fa-angle-left" />
                            </b-btn>
                        </span>
                    </div>
                </div>
                <div class="col" style="max-width: 480px">
                <span>Assigned Language After Update</span>
                    <div class="dx-grid-container">
                        <dx-tree-list
                            id="originalTreeList"
                            ref="originalTreeList"
                            key-expr="id"
                            height="400px"
                            parent-id-expr="parentId"
                            :data-source="originalLanguageDataSource"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :allow-column-resizing="true"
                            v-model:expanded-row-keys="expandedOriginalRowKeys"
                            v-model:selected-row-keys="selectedOriginalRowKeys"
                            @selection-changed="onSelectionChanged">
                            <dx-selection mode="multiple" />
                            <dx-editing
                                :allow-adding="false"
                                :allow-updating="false"
                                :allow-deleting="false"
                                mode="row"
                            />
                            <dx-column
                                data-field="descriptionDisplay"
                                data-type="string"
                                caption="Description"
                                :width="230"
                                >
                            </dx-column>
                            <dx-column
                                data-field="code"
                                data-type="string"
                                caption="Code"
                                :width="120"
                                >
                            </dx-column>
                            <dx-column
                                data-field="appliesToDisplay"
                                data-type="string"
                                caption="Applies To"
                                :width="90"
                                >
                            </dx-column>
                        </dx-tree-list>
                    </div>
                </div>
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DxTreeList, DxColumn, DxEditing, DxSelection } from "devextreme-vue/tree-list";
    import { AssignedLanguageDataMapper } from "../../models"

    export default {
        name: "CommitmentPolicyAssignedLanguageMapping",
        props: {
            stepNumber: { type: Number, default: 6 },
            mapping: [],
        },
        components: {
            DxTreeList,
            DxColumn,
            DxEditing,
            DxSelection,
        },
        data() {
            return {
                originalItems: [],
                pendingItems: [],
                expandedOriginalRowKeys: [],
                expandedPendingRowKeys: [],
                selectedOriginalRowKeys: [],
                selectedPendingRowKeys: [],
                selectedPendingItems: [],
                selectedOriginalItems: [],
            }
        },

        created() {
            this.initTreeListDataSources();
            this.expandedPendingRowKeys = this.pendingTabsWithSectionsKeys.slice();
            this.expandedOriginalRowKeys = this.originalTabsWithSectionsKeys.slice()
        },

        watch: {

        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            treeContainerClassAttr() {
                return [
                    "grid-container",
                    "rq-grid-with-toolbar",
                    "rq-grid-header-floating",
                    "rq-grid-multi-select",
                    "rq-grid-allow-select-all",
                    "rq-grid-with-footer-actions",
                    "mt-2"
                ];
            },
            pendingTabsWithSectionsKeys() { return _.pull(_.uniq(_.map(this.pendingItems, "parentId")), 0); },
            originalTabsWithSectionsKeys() { return _.pull(_.uniq(_.map(this.originalItems, "parentId")), 0); },
            pendingAssignedLanguageMapping(){
                return this.mapping.pendingPolicyAssignedLanguageDataMapping || null;
            },
            originalAssignedLanguageMapping(){
                return this.mapping.originalAssignedLanguage || null;
            },
        },

        methods:{
            initTreeListDataSources() {
                const self = this;
                self.originalLanguageDataSource = {
                    key: "id",
                    load: () => Promise.resolve(self.originalItems),
                };
                self.pendingLanguageDataSource = {
                    key: "id",
                    load: () => Promise.resolve(self.pendingItems),
                };
                self.mapData();
                self.refresh();
            },

            mapData() {
                const self = this;
                let originalItems = [];
                let pendingItems = [];

                // Set up the sections
                let originalMappings = self.originalAssignedLanguageMapping;
                self.originalSections = self.getAssignedLanguageSections(originalMappings);
                let pendingMappings = self.pendingAssignedLanguageMapping;
                self.pendingSections = self.getAssignedLanguageSections(pendingMappings);

                //need to add any sections that exist in pending but are missing in original so they can be assigned in the UI
                _.forEach(self.pendingSections, s => {
                    let foundSection =  _.find(self.originalSections, os => { return os.listType === s.listType});
                    if(!foundSection)
                        self.originalSections.push(s);
                });

                //map original items
                _.forEach(self.originalSections, s => {
                    // Identify and filter data belonging to a particular section for top level items
                    let originalParentItemsForSection = _.filter(originalMappings, m => m.listType === s.listType && _.isNull(m.assignedLanguageParentID)); 
                    let originalParentItems = _.map(originalParentItemsForSection, i => {
                        // top level item
                        i.isSection = false;
                        return new AssignedLanguageDataMapper(i, s)
                    });
                    originalItems.push(s, ...originalParentItems); // add section and top parent level items

                    // Identify and filter data belonging to a paricualr section for for child items
                    let originalChildItemsForSection =  _.filter(originalMappings, m => m.listType === s.listType && !_.isNull(m.assignedLanguageParentID)); 

                    _.forEach(originalChildItemsForSection, child => {
                        // child item 
                        let parentItem = _.find(originalParentItems, p =>child.assignedLanguageParentID === p.assignedLanguageID);                        
                        if (!_.isNull(parentItem)) {
                            let originalChildItem = new AssignedLanguageDataMapper(child, parentItem);
                            originalChildItem.isChild = true;
                            originalItems.push(originalChildItem); // add child item associated with a parent item
                        }
                    });
                });    
                self.originalItems = originalItems;            

                //map pending items
                _.forEach(self.pendingSections, s => {
                    // Identify and filter data belonging to a particular section for top level items
                    let pendingParentItemsForSection = _.filter(pendingMappings, m => m.listType === s.listType && _.isNull(m.assignedLanguageParentID)); 
                    let pendingParentItems = _.map(pendingParentItemsForSection, i => {
                        // top level item
                        i.isSection = false;
                        return new AssignedLanguageDataMapper(i, s)
                    });
                    pendingItems.push(s, ...pendingParentItems); // add section and top parent level items

                    // Identify and filter data belonging to a paricualr section for for child items                    
                    let pendingChildItemsForSection =  _.filter(pendingMappings, m => m.listType === s.listType && !_.isNull(m.assignedLanguageParentID)); 

                    _.forEach(pendingChildItemsForSection, child => {
                        // child item
                        let parentItem = _.find(pendingParentItems, p =>child.assignedLanguageParentID === p.pcStageAssignedLanguageID);                        
                        if (!_.isNull(parentItem)) {
                            let pendingChildItem = new AssignedLanguageDataMapper(child, parentItem);
                            pendingChildItem.isChild = true;
                            pendingItems.push(pendingChildItem); // add child item associated with a parent item
                        }
                    });
                });
                self.pendingItems = pendingItems;
            },

            getAssignedLanguageSections (mappings) {
                let uniqueListTypes = [];
                _.forEach(mappings, o =>{
                    if (!_.some(uniqueListTypes, { 'listType': o.listType } ))
                        uniqueListTypes.push(o);
                });
                return _.map(uniqueListTypes, t => {
                    t.isSection = true;
                    return new AssignedLanguageDataMapper(t)
                });
            },

            moveRightDisabled() {
                let selectedPendingItems = _.filter(this.selectedPendingItems, p => !_.isNil(p.parentId));
                let selectedTargetSection = _.filter(this.selectedOriginalItems, i => _.isNil(i.parentId));
                if (selectedPendingItems.length === 0 || selectedTargetSection.length === 0) return true;
                else return false;
            },

            moveLeftDisabled() {
                let selectedOriginalItems = _.filter(this.selectedOriginalItems, o => !_.isNil(o.parentId));
                let isPending = _.every(selectedOriginalItems, o => o.isPendingItem);
                if (selectedOriginalItems.length === 0 || !isPending) return true;
                else return false;
            },

            moveRight() {
                let self = this;
                let selectedPendingItems = _.filter(this.selectedPendingItems, p => !_.isNil(p.parentId));
                let targetSection = _.find(self.selectedOriginalItems, o => _.isNil(o.parentId));
                if (!targetSection) return;

                _.forEach(selectedPendingItems, p => {
                    // Only assign parentId for parent items
                    if (!p.isChild) {
                        p.parentId = targetSection.id;
                    }
                    p.listType = targetSection.listType;
                    p.sectionId = targetSection.sectionId;
                    _.remove(self.pendingItems, p);
                    self.originalItems.push(p);
                });
                self.refresh();
            },

            moveLeft() {
                let self = this;
                let selectedOriginalItems = self.selectedOriginalItems;

                _.forEach(selectedOriginalItems, p => {
                    p.parentId = p.originalParentId;
                    p.listType = p.originalListType;
                    p.sectionId = p.originalSectionId;
                    _.remove(self.originalItems, p);
                    self.pendingItems.push(p);
                });
                self.refresh();
            },

            onSelectionChanged(e) {
                const self = this;
                let elementName = e.element[0].id;
                let selectOriginalItems = elementName === "originalTreeList";
                let expectedRowKeys = [];
                let selectedItems = [];

                if (selectOriginalItems) {
                    expectedRowKeys = e.currentSelectedRowKeys.length > 0 ? e.currentSelectedRowKeys : e.selectedRowKeys;
                    selectedItems = _.filter(e.selectedRowsData, d => d.id === expectedRowKeys[0]);
                    self.selectedOriginalRowKeys = expectedRowKeys;
                    self.selectedOriginalItems = selectedItems;
                }
                else {
                    expectedRowKeys = self.getExpectedRowKeys(e, selectOriginalItems);
                    selectedItems = e.selectedRowsData.slice();
                    self.selectedPendingRowKeys = expectedRowKeys;
                    self.selectedPendingItems = selectedItems;

                    // RQO-35473 - For now, current design is for any selected item, if it is a child item of any nested item we 
                    // traverse and select the nested items till the top level item
                    // Note: Will have to get more use cases to consider and capture more accurate UI behavior
                    _.forEach(self.selectedPendingItems, s => {
                        self.selectChildUpTopItem(s);
                    });
                }

            },

            // Recursive method to traverse nested list till top level parent is reached
            selectChildUpTopItem(item) {
                const self = this;
                if (!item.isSection && !_.isNull(item.parentId)) {
                    let parentItem = _.find(self.pendingItems, i => i.id === item.parentId && !i.isSection);
                    
                    if (parentItem == null) return;
                    if (!self.selectedPendingItems.includes(parentItem)) {
                        self.selectedPendingItems.push(parentItem);
                        self.selectedPendingRowKeys.push(parentItem.id);
                        
                        self.selectChildUpToParent(parentItem);
                    }
                }
            },

            getExpectedRowKeys({ selectedRowKeys, selectedRowsData, currentDeselectedRowKeys }, selectOriginalItems) {
                const self = this;
                let selectionList = selectOriginalItems ? self.originalItems : self.pendingItems;
                let selectedTabs = _.filter(selectedRowsData, item => _.isNil(item.parentId));
                let selectedTabKeys = _.map(selectedTabs, "id");
                let tabSections = _.filter(selectionList, item => _.includes(selectedTabKeys, item.parentId));
                let tabSectionKeys = _.map(tabSections, "id");
                let result = _.uniq(_.concat(selectedRowKeys, tabSectionKeys));

                if(currentDeselectedRowKeys.length > 0) {
                    let deselectSections = _.filter(selectionList, item => _.includes(currentDeselectedRowKeys, item.parentId));
                    let deselectSectionKeys = _.map(deselectSections, "id");
                    _.pullAll(result, deselectSectionKeys);
                }

                return result;
            },

            refresh() {
                this.refreshOriginalTreeList();
                this.refreshPendingTreeList();
            },

            refreshOriginalTreeList(...params) {
                return _.invoke(this, `$refs.originalTreeList.instance.refresh`, ...params);
            },

            refreshPendingTreeList(...params) {
                return _.invoke(this, `$refs.pendingTreeList.instance.refresh`, ...params);
            },

            onMoveNext(){
                this.mapping.originalAssignedLanguage = _.filter(this.originalItems, o => !_.isNil(o.parentId));
                return true;
            },
        }
    }
</script>
